export const actionTypes = {
  GetListByClass: "waiting/GET_LIST_BY_CLASS",
  GetAll: "waiting/GET_ALL",
  SetState: "waiting/SET_STATE",
  SetSelected: "waiting/SET_SELECTED",
  UpdateWaiting: "waiting/UPDATE_WAITING",
  DeleteWaiting: "waiting/DELETE_WAITING",
  GetSelected: "waiting/GET_SELECTED",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetSelected: (payload) => ({ type: actionTypes.SetSelected, payload }),
  GetSelected: (payload) => ({ type: actionTypes.GetSelected, payload }),
  GetAll: (payload) => ({ type: actionTypes.GetAll, payload }),
  UpdateWaiting: (payload) => ({ type: actionTypes.UpdateWaiting, payload }),
  DeleteWaiting: (payload) => ({ type: actionTypes.DeleteWaiting, payload }),
};
