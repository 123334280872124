import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call, all, takeEvery } from "redux-saga/effects";
import {
  getUserByToken,
  register,
  login,
  updateProfile,
  updateParentProfile,
  validateAUser,
  checkEmailAddress,
} from "./authCrud";
import { toast } from "../../../../app/components/Toastr";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UpdateProfile: "[UpdateProfile] Action",
  UpdateParentProfile: "[UpdateParentProfile] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetState: "[SetState] Auth",
  CheckEmail: "[User] Check EmaiL",
  ValidateUser: "[User] Validate User",
  SetUser: "[SetUser] Action",
};

const initialAuthState = {
  authToken: undefined,
  user: undefined,
  show: true,
  meta: {},
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken: `Bearer ${authToken}`, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetState: {
        const { info, meta, show } = action.payload;

        return {
          ...state,
          show,
          authToken:
            info?.token && info?.token?.length > 0
              ? {
                  token:
                    info?.token && info?.token?.length > 0
                      ? `Bearer ${info.token}`
                      : undefined,
                }
              : undefined,
          user: info.user,
          meta: { ...meta },
        };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return {
          ...state,
          user: user,
          info: {
            ...state.info,
            user: user,
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  loginUser: (result) => ({
    type: actionTypes.Login,
    payload: { result },
  }),
  register: ({ authToken, history }) => ({
    type: actionTypes.Register,
    payload: { authToken, authToken },
  }),
  updateProfile: (result) => ({
    type: actionTypes.UpdateProfile,
    payload: { result },
  }),
  updateParentProfile: (result) => ({
    type: actionTypes.UpdateParentProfile,
    payload: { result },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CheckEmail: (payload) => ({ type: actionTypes.CheckEmail, payload }),
  SetUser: (payload) => ({ type: actionTypes.SetUser, payload }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(
      actions.setState({
        info: { user: undefined, token: undefined },
        meta: {
          login: {
            loading: true,
            error: false,
            message: null,
          },
        },
      })
    );
    try {
      const result = yield call(login, payload);
      yield put({ type: "enrolments/HIDE_MODAL" });
      return yield put(
        actions.setState({
          info: result.data.info,
          meta: null,
        })
      );
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      }
      return yield put(
        actions.setState({
          info: { user: undefined, token: undefined },
          meta: {
            login: {
              loading: false,
              error: true,
              message: error.response.data.error,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.Register, function* registerSaga({ payload }) {
    yield put(
      actions.setState({
        info: { user: undefined, token: undefined },
        meta: {
          register: {
            loading: true,
            error: false,
            message: null,
          },
        },
      })
    );
    try {
      const { history, authToken } = payload;

      const result = yield call(register, authToken);
      if (result) {
        yield put(
          actions.setState({
            info: result.data.info,
            meta: null,
          })
        );
        return setTimeout(
          () => history.push("/settings/organisation-details"),
          200
        );
      }
    } catch (error) {
      console.log(error);
      return yield put(
        actions.setState({
          info: { user: undefined, token: undefined },
          meta: {
            register: {
              loading: false,
              error: true,
              message: error.response.data
                ? error.response.data.error
                : "Server Error" || "Internal Server Error",
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.UpdateProfile, function* registerSaga({
    payload,
  }) {
    try {
      const {
        data: { results, message },
      } = yield call(updateProfile, payload);
      if (results) {
        yield put(actions.fulfillUser(results));
        toast.success(message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  });

  yield takeLatest(actionTypes.UpdateParentProfile, function* registerSaga({
    payload,
  }) {
    try {
      const {
        data: { results, message },
      } = yield call(updateParentProfile, payload);
      if (results) {
        yield put(actions.fulfillUser(results));
        toast.success(message);
      }
    } catch (error) {
      console.table(error);
      toast.error(error.message);
    }
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.CheckEmail, function* checkEmail(payload) {
    try {
      const results = yield call(checkEmailAddress, { email: payload.email });
      if (results) {
        yield put(
          actions.setState({
            info: results.data.message,
          })
        );
      }
    } catch (error) {
      console.log(error, error.response);
      yield put(
        actions.setState({
          info: error.response.data.message,
        })
      );
    }
  });

  yield takeLatest(actionTypes.ValidateUser, function* validateALoggedInUser({
    payload,
  }) {
    try {
      const results = yield call(validateAUser, payload);
      if (results) {
        yield put(
          actions.SetUser({
            user: results.data,
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Unauthenticated User.");
      console.log(error, error.response);
      setTimeout(() => {
        localStorage.removeItem("persist:auth");
        window.location.href = "/";
      }, 200);
    }
  });
}
