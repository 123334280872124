import { actionTypes } from "../actions/feedback";

const initialFeedbackReducer = {
  list: [],
  tutorList: [],
  parentList: [],
  selected: {},
};

const feedbackReducer = (state = initialFeedbackReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.GetSelectedFeedback: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    case actionTypes.SetTutorList: {
      const { tutorList } = action.payload;

      return {
        ...state,
        tutorList,
      };
    }

    case actionTypes.SetParentList: {
      const { parentList } = action.payload;

      return {
        ...state,
        parentList,
      };
    }

    default:
      return state;
  }
};

export default feedbackReducer;
