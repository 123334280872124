import {
  getAll,
  deleteStudent,
  create,
  getSelected,
  updateSelected,
  getStudentsByParent,
} from "../../services/student";
import { actionTypes, actions } from "../actions/students";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    // yield put(
    //   actions.setState({
    //     meta: {
    //       list: {
    //         loading: true,
    //       },
    //     },
    //   })
    // );
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      // return yield put(
      //   actions.setState({
      //     meta: {
      //       list: {
      //         loading: false,
      //       },
      //     },
      //   })
      // );
    }
  });

  yield takeLatest(actionTypes.GetStudentsByParent, function* getStudentsParent(
    payload
  ) {
    try {
      const result = yield call(getStudentsByParent, payload);

      return yield put(
        actions.setState({
          studentsByParents: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.UpdateStudent, function* updateSelectedStudent(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedStudent, function* getSelectedStudent(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedStudent({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateStudent, function* createStudent(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteStudent, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteStudent, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
