import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getListByClass({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/waitingList/${payload.classId}`);
}

export function getAll({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.filter) {
    return baseApi.get(`/waitingList/all`, {
      params: { filter: payload.filter },
    });
  } else {
    return baseApi.get(`/waitingList/all`);
  }
}

export function getOne({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/waitingList/get/${payload.id}`);
}

export function deleteWaiting({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/waitingList/delete/${payload.id}`);
}

export function update({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/waitingList/update/${payload.id}`, payload);
}
