export const actionTypes = {
  GetList: "locations/GET_LIST",
  GetGroupList: "locations/GET_GROUP_LIST",
  GetListByUuid: "locations/GET_LIST_BY_UUID",
  SetState: "locations/SET_STATE",
  CreateLocations: "locations/CREATE_LOCATION",
  EditLocations: "locations/EDIT_LOCATION",
  DeleteLocations: "locations/DELETE_LOCATION",
  GetSelectedLocations: "locations/GET_SELECTED_LOCATION",
  SetSelectedLocations: "locations/SET_SELECTED_LOCATION",
  UpdateLocations: "locations/UPDATE_LOCATION",
  SearchNearLocation: "locations/SEARCH_NEAR_LOCATION",
  SetSearchedResults: "locations/SET_SEARCHED_RESULTS",
  SetMapZoom: "locations/SET_MAP_ZOOM",
  SetMapSelectedLocation: "locations/SET_MAP_SELECTED_LOCATION",
  SetSelectedLocation: "locations/SET_SELECTED_LOCATION",
  GetAllLocationsAndVenue: "locations/GET_ALL_VENUES_AND_LOCATIONS",
};

export const actions = {
  SetMapSelectedLocation: (payload) => ({
    type: actionTypes.SetMapSelectedLocation,
    payload,
  }),
  SetSelectedLocation: (payload) => ({
    type: actionTypes.SetSelectedLocation,
    payload,
  }),
  SetMapZoom: (payload) => ({
    type: actionTypes.SetMapZoom,
    payload,
  }),
  setSearchedResults: (payload) => ({
    type: actionTypes.SetSearchedResults,
    payload,
  }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  GetListByUuid: (payload) => ({ type: actionTypes.GetListByUuid, payload }),
  createLocation: (payload) => ({
    type: actionTypes.CreateLocations,
    payload,
  }),
  editLocation: (payload) => ({
    type: actionTypes.EditLocations,
    payload,
  }),
  getSelectedLocation: (payload) => ({
    type: actionTypes.GetSelectedLocations,
    payload,
  }),
  setSelectedLocation: (payload) => ({
    type: actionTypes.SetSelectedLocations,
    payload,
  }),
  SearchNearLocation: (payload) => ({
    type: actionTypes.SearchNearLocation,
    payload,
  }),
};
