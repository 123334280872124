import { actionTypes } from "../actions/locations";

const initialLocationReducer = {
  list: [],
  selected: {},
  selectedLocations: [],
  searched: [],
  zoom: 12,
  selectedInMap: {},
  locationsAndVenues: [],
  // meta: {},
};

const locationReducer = (state = initialLocationReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, selectedLocations, locationsAndVenues } = action.payload;

      return {
        ...state,
        list,
        selectedLocations,
        locationsAndVenues,
      };
    }

    case actionTypes.SetSelectedLocation: {
      const { selectedInMap } = action.payload;
      return {
        ...state,
        selectedInMap,
      };
    }

    case actionTypes.SetSearchedResults: {
      const { searched } = action.payload;
      return {
        ...state,
        searched,
      };
    }

    case actionTypes.SetMapZoom: {
      const { zoom } = action.payload;
      return {
        ...state,
        zoom,
      };
    }

    case actionTypes.SetSelectedLocations: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    default:
      return state;
  }
};

export default locationReducer;
