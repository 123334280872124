import { actionTypes } from "../actions/venues";

const initialVenuesState = {
  list: [],
  selectedLocations: [],
  selected: {},
  // meta: {},
};

const venuesReducer = (state = initialVenuesState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, selectedLocations } = action.payload;
      return {
        ...state,
        list,
        selectedLocations,
      };
    }

    case actionTypes.GetSelectedVenue: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default venuesReducer;
