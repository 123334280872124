import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import lessonReducer from "./reducers/lessons";
import instructorsReducer from "./reducers/instructors";
import levelReducer from "./reducers/levels";
import locationsReducer from "./reducers/locations";
import programsReducer from "./reducers/programs";
import venuesReducer from "./reducers/venues";
import parentReducer from "./reducers/parents";
import studentReducer from "./reducers/students";
import lessonTypesReducer from "./reducers/lessonTypes";
import enrolmentReducer from "./reducers/enrolments";
import settingReducer from "./reducers/settings";
import classReducer from "./reducers/classes";
import tutorReducer from "./reducers/tutors";
import organisationReducer from "./reducers/organisations";
import attendanceReducer from "./reducers/attendance";
import publicHolidaysReducer from "./reducers/publicholidays";
import categoriesReducer from "./reducers/categories";
import productsReducer from "./reducers/products";
import notificationsReducer from "./reducers/notifications";
import birthdayPartyReducer from "./reducers/birthdayParty";
import orderReducer from "./reducers/orders";
import loaderReducer from "./reducers/loader";
import waitingListReducer from "./reducers/waitingList";
import bookingReducer from "./reducers/booking";
import inflatableReducer from "./reducers/inflatable";
import couponReducer from "./reducers/coupon";
import bookaCoachReducer from "./reducers/bookacoach";
import playReducer from "./reducers/play";
import emailTemplateReducer from "./reducers/emailTemplate";
import locationSurchargeReducer from "./reducers/locationSurcharge";
import feedbackReducer from "./reducers/feedback";
import makeupLessonReducer from "./reducers/makeupLesson";

import lessonSaga from "./sagas/lessons";
import instructorSaga from "./sagas/instructors";
import levelSaga from "./sagas/levels";
import locationsSaga from "./sagas/locations";
import programsSaga from "./sagas/programs";
import venuesSaga from "./sagas/venues";
import parentsSaga from "./sagas/parents";
import studentsSaga from "./sagas/students";
import lessonTypesSaga from "./sagas/lessonTypes";
import enrolmentSaga from "./sagas/enrolments";
import settingSaga from "./sagas/settings";
import classSaga from "./sagas/classes";
import tutorsSaga from "./sagas/tutors";
import organisationsSaga from "./sagas/organisations";
import attendanceSaga from "./sagas/attendance";
import publicHolidaysSaga from "./sagas/publicholidays";
import categoriesSaga from "./sagas/categories";
import productsSaga from "./sagas/products";
import notificationSaga from "./sagas/notifications";
import birthdayPartySaga from "./sagas/birthdayParty";
import orderSaga from "./sagas/orders";
import waitingListSaga from "./sagas/waitingList";
import bookingSaga from "./sagas/booking";
import inflatableSaga from "./sagas/inflatable";
import couponSaga from "./sagas/coupon";
import bookaCoachSaga from "./sagas/bookacoach";
import playSaga from "./sagas/play";
import emailTemplateSaga from "./sagas/emailTemplate";
import locationSurchargeSaga from "./sagas/locationSurcharge";
import feedbackSaga from "./sagas/feedback";
import makeupLessonSaga from "./sagas/makeupLesson";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  enrolments: enrolmentReducer,
  lessons: lessonReducer,
  instructors: instructorsReducer,
  levels: levelReducer,
  locations: locationsReducer,
  programs: programsReducer,
  venues: venuesReducer,
  parents: parentReducer,
  students: studentReducer,
  lessonTypes: lessonTypesReducer,
  settings: settingReducer,
  classes: classReducer,
  tutors: tutorReducer,
  organisations: organisationReducer,
  attendance: attendanceReducer,
  publicHolidays: publicHolidaysReducer,
  categories: categoriesReducer,
  products: productsReducer,
  notifications: notificationsReducer,
  loader: loaderReducer,
  birthdayParty: birthdayPartyReducer,
  orders: orderReducer,
  waitingList: waitingListReducer,
  booking: bookingReducer,
  inflatable: inflatableReducer,
  coupon: couponReducer,
  bookaCoach: bookaCoachReducer,
  play: playReducer,
  emailTemplate: emailTemplateReducer,
  locationSurcharge: locationSurchargeReducer,
  feedback: feedbackReducer,
  makeupLessons: makeupLessonReducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    lessonSaga(),
    instructorSaga(),
    levelSaga(),
    locationsSaga(),
    programsSaga(),
    venuesSaga(),
    parentsSaga(),
    studentsSaga(),
    lessonTypesSaga(),
    enrolmentSaga(),
    settingSaga(),
    classSaga(),
    tutorsSaga(),
    organisationsSaga(),
    attendanceSaga(),
    publicHolidaysSaga(),
    categoriesSaga(),
    productsSaga(),
    notificationSaga(),
    birthdayPartySaga(),
    orderSaga(),
    waitingListSaga(),
    bookingSaga(),
    inflatableSaga(),
    couponSaga(),
    bookaCoachSaga(),
    playSaga(),
    emailTemplateSaga(),
    locationSurchargeSaga(),
    feedbackSaga(),
    makeupLessonSaga(),
  ]);
}
