import {
  getOne,
  getAll,
  createClass,
  getAllLessonsByClass,
  getSelected,
  // updateLesson,
  getAllClassesByLocation,
} from "../../services/classes";
import * as SettingsActions from "../actions/settings";

import { actionTypes, actions } from "../actions/classes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      const result = yield call(getOne, payload);
      yield put(
        actions.setState({
          classesByLocation: [result.data],
          selected: result.data,
        })
      );

      yield put(
        SettingsActions.actions.getPublicSetting({
          uuid: "8c31d4f5-cbda-4016-ba98-7c98d5bb37ae",
          business: result.data.business,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetClassesByLocation,
    function* getAllClassesByLocations(payload) {
      try {
        const result = yield call(getAllClassesByLocation, payload.payload);

        return yield put(
          actions.setState({
            classesByLocation: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetLessonsByClass,
    function* GetTheLessonsByClass(payload) {
      try {
        const result = yield call(getAllLessonsByClass, payload.payload);

        return yield put(
          actions.SetLessonsByClass({
            lessonsByClass: result.data.results[0],
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.CreateClasses, function* createClasses(payload) {
    try {
      const result = yield call(createClass, payload.payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });
}
