export const actionTypes = {
  GetList: "makeuplesson/GET_LIST",
  SetState: "makeuplesson/SET_STATE",

  GetFromParent: "makeuplesson/GET_MAKEUP_LESSON_FROM_PARENT",
  SetFromParent: "makeuplesson/SET_MAKEUP_LESSON_FROM_PARENT",

  GetSelected: "makeuplesson/GET_SELECTED_MAKEUP_LESSON",
  SetSelected: "makeuplesson/SET_SELECTED_MAKEUP_LESSON",

  CreateMakeupLesson: "makeuplesson/CREATE_MAKEUP_LESSON",
  UpdateMakeupLesson: "makeuplesson/UPDATE_MAKEUP_LESSON",
  DeletetMakeupLesson: "makeuplesson/SUBMIT_MAKEUP_LESSON",

  UpdateExpiryDate: "makeuplesson/UPDATE_EXPIRY_DATE",
  ChangeStatus: "makeuplesson/CHANGE_STATUS",
};

export const actions = {
  GetList: (payload) => ({ type: actionTypes.GetList, payload }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  GetSelected: (payload) => ({
    type: actionTypes.GetSelected,
    payload,
  }),
  SetSelected: (payload) => ({
    type: actionTypes.SetSelected,
    payload,
  }),
  GetFromParent: (payload) => ({
    type: actionTypes.GetFromParent,
    payload,
  }),
  SetFromParent: (payload) => ({
    type: actionTypes.SetFromParent,
    payload,
  }),
  CreateMakeupLesson: (payload) => ({
    type: actionTypes.CreateMakeupLesson,
    payload,
  }),
  UpdateMakeupLesson: (payload) => ({
    type: actionTypes.UpdateMakeupLesson,
    payload,
  }),
  DeletetMakeupLesson: (payload) => ({
    type: actionTypes.DeletetMakeupLesson,
    payload,
  }),
  UpdateExpiryDate: (payload) => ({
    type: actionTypes.UpdateExpiryDate,
    payload,
  }),
  ChangeStatus: (payload) => ({
    type: actionTypes.ChangeStatus,
    payload,
  }),
};
