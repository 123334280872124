import { actionTypes } from "../actions/makeupLesson";

const initiaMakeupLessonReducer = {
  list: [],
  parentList: [],
  meta: {},
  selected: {},
};

const makeupLessonReducer = (state = initiaMakeupLessonReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }

    case actionTypes.SetSelected: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetFromParent: {
      const { parentList } = action.payload;
      return {
        ...state,
        parentList,
      };
    }

    default:
      return state;
  }
};

export default makeupLessonReducer;
