import {
  getAll,
  getGroupList,
  createNewNotifications,
  deleteNotification,
  updateNotifications,
} from "../../services/notifications";
import { actionTypes, actions } from "../actions/notifications";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetGroupList, function* getGroupLists(payload) {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getGroupList, payload.payload);
      return yield put(
        actions.SetGroupList({
          groupList: result.data.results,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.CreateNotifications,
    function* createNotification(payload) {
      try {
        const result = yield call(createNewNotifications, payload.payload);
        toast.success(result.data.message);
        yield put(
          actions.SetDrawer({
            drawer: false,
          })
        );
        yield put({
          type: actionTypes.GetList,
        });
      } catch (error) {
        toast.error(error.response.data.error);
        return yield put(
          actions.setState({
            meta: {
              list: {
                loading: false,
              },
            },
          })
        );
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateNotifications,
    function* updateNotification(payload) {
      try {
        const result = yield call(updateNotifications, payload.payload);
        toast.success(result.data.message);
        yield put(
          actions.SetDrawer({
            drawer: false,
          })
        );
        yield put({
          type: actionTypes.GetList,
        });
      } catch (error) {
        toast.error(error.response.data.error);
        return yield put(
          actions.setState({
            meta: {
              list: {
                loading: false,
              },
            },
          })
        );
      }
    }
  );

  yield takeLatest(
    actionTypes.DeleteNotifications,
    function* deleteNotifications(payload) {
      try {
        const result = yield call(deleteNotification, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.OpenDrawer, function* openDrawer() {
    yield put(
      actions.SetDrawer({
        drawer: true,
      })
    );
  });

  yield takeLatest(actionTypes.CloseDrawer, function* closeDrawer() {
    yield put(
      actions.SetDrawer({
        drawer: false,
      })
    );
  });
}
