export const actionTypes = {
  GetList: "classes/GET_LIST",
  GetGroupList: "classes/GET_GROUP_LIST",
  SetState: "classes/SET_STATE",
  CreateClasses: "classes/CREATE_CLASS",
  UpdateClasses: "classes/UPDATE_CLASS",
  GetClassesByLocation: "classes/GET_CLASSES_BY_LOCATION",
  GetLessonsByClass: "classes/GET_LESSONS_BY_CLASS",
  SetLessonsByClass: "classes/SET_LESSONS_BY_CLASS",
  GetSelected: "classes/GET_SELECTED",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateLessons: (payload) => ({ type: actionTypes.CreateLessons, payload }),
  GetSelected: (payload) => ({ type: actionTypes.GetSelected, payload }),
  UpdateLessons: (payload) => ({ type: actionTypes.UpdateLessons, payload }),
  GetLessonsByLocation: (payload) => ({
    type: actionTypes.GetClassesByLocation,
    payload,
  }),

  GetLessonsByClass: (payload) => ({
    type: actionTypes.GetLessonsByClass,
    payload,
  }),

  SetLessonsByClass: (payload) => ({
    type: actionTypes.SetLessonsByClass,
    payload,
  }),
};
