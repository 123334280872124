import { isEmpty } from "lodash";
import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/venues/all");
}

export function getAllVenuesByUuid({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.tag) {
    return baseApi.get(`/venues/location/${payload.uuid}`, {
      params: { tag: payload.tag },
    });
  } else {
    return baseApi.get(`/venues/location/${payload.uuid}`);
  }
}

export function getSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.id) {
    return baseApi.get(`/venues/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();
  // for (var i in payload) {
  //   params.set(i, payload[i]);
  // }

  params.append("address", payload.address);
  params.append("address1", payload.address1);
  params.append("city", payload.city);
  params.append("venueImage", payload.venueImage);
  params.append("name", payload.name);
  params.append("contacts", JSON.stringify(payload.contacts));
  params.append("postcode", payload.postcode);
  params.append("state", payload.state);
  params.append("suburb", payload.suburb);
  params.append("status", payload.status);
  params.append("latitude", payload.latitude);
  params.append("longitude", payload.longitude);

  return baseApi.post(`/venues/create`, params, {
    headers: {
      "Content-Type": "multipart/form-data, charset=utf-8",
    },
  });
}

export function deleteVenue(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/venues/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  let params = new FormData();
  // for (var i in payload) {
  //   params.set(i, payload[i]);
  // }

  params.append("venueImage", payload.venueImage);

  if (payload.address !== undefined && payload.address !== "") {
    params.append("address", payload.address);
  }
  if (payload.address1 !== undefined && payload.address1 !== "") {
    params.append("address1", payload.address1);
  }
  if (payload.city !== undefined && payload.city !== "") {
    params.append("city", payload.city);
  }
  if (payload.name !== undefined && payload.name !== "") {
    params.append("name", payload.name);
  }
  if (
    payload.contacts !== undefined &&
    payload.contacts !== "" &&
    !isEmpty(payload.contacts)
  ) {
    params.append("contacts", JSON.stringify(payload.contacts));
  }
  if (payload.postcode !== undefined && payload.postcode !== "") {
    params.append("postcode", payload.postcode);
  }
  if (payload.state !== undefined && payload.state !== "") {
    params.append("state", payload.state);
  }
  if (payload.suburb !== undefined && payload.suburb !== "") {
    params.append("suburb", payload.suburb);
  }
  if (payload.status !== undefined && payload.status !== "") {
    params.append("status", payload.status);
  }
  if (payload.status !== undefined && payload.latitude !== "") {
    params.append("latitude", payload.latitude);
  }
  if (payload.status !== undefined && payload.longitude !== "") {
    params.append("longitude", payload.longitude);
  }

  return baseApi.put(`/venues/update/${payload.id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data, charset=utf-8",
    },
  });
}
