import {
  getAll,
  create,
  deleteBookaCoach,
  updateSelected,
  getSelected,
  getCancelledList,
  getPast,
  getUpcoming,
  getScheduleBookaCoach,
} from "../../services/bookacoach";
import { actionTypes, actions } from "../actions/bookacoach";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetSelectedBookaCoach,
    function* getSelectedBookaCoachById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.SetSelectedBookaCoach({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetScheduleBookaCoach,
    function* getScheduleBookaCoachList() {
      try {
        const result = yield call(getScheduleBookaCoach);

        return yield put(
          actions.SetScheduleBookaCoach({
            scheduleList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getCancelledBirthdayList() {
      try {
        const result = yield call(getCancelledList);

        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetPastBookaCoach, function* getPastList() {
    try {
      const result = yield call(getPast);

      return yield put(
        actions.SetPastBookaCoach({
          pastList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetFutureBookaCoach, function* getFutureList() {
    try {
      const result = yield call(getUpcoming);
      return yield put(
        actions.SetFutureBookaCoach({
          upcomingList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateBookaCoach, function* createBookaCoach(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.DeleteBookaCoach,
    function* deleteSelectedBookaCoach(payload) {
      try {
        const result = yield call(deleteBookaCoach, payload.payload);
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateBookaCoach,
    function* updateSelectedBookaCoach(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);

        return toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
