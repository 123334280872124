import {
  getAll,
  deleteParent,
  create,
  getSelected,
  updateSelected,
  getAllPaymentMethodsOfParent,
} from "../../services/parent";
import { actionTypes, actions } from "../actions/parents";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    // yield put(
    //   actions.setState({
    //     meta: {
    //       list: {
    //         loading: true,
    //       },
    //     },
    //   })
    // );
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      // return yield put(
      //   actions.setState({
      //     meta: {
      //       list: {
      //         loading: false,
      //       },
      //     },
      //   })
      // );
    }
  });

  yield takeLatest(actionTypes.UpdateParent, function* updateSelectedParent(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.getSelectedParent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedParent, function* getSelectedParent(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedParent({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateParent, function* createParent(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteParent, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteParent, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetPaymentMethods, function* getList(payload) {
    try {
      const result = yield call(getAllPaymentMethodsOfParent, payload);
      return yield put(
        actions.setPaymentsMethods({
          paymentMethods: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });
}
