export const actionTypes = {
  GetList: "enrolments/GET_LIST",
  CreateEnrolment: "enrolments/CREATE_ENROLMENT",
  GetPrevEnrolmentByParent: "enrolments/GET_PREV_ENROLMENT_BY_PARENT",
  SetPrevEnrolmentByParent: "enrolments/SET_PREV_ENROLMENT_BY_PARENT",
  GetFutureEnrolmentByParent: "enrolments/GET_FUTURE_ENROLMENT_BY_PARENT",
  SetFutureEnrolmentByParent: "enrolments/SET_FUTURE_ENROLMENT_BY_PARENT",
  GetEnrolmentByParent: "enrolments/GET_ENROLMENT_BY_PARENT",
  SetEnrolmentByParent: "enrolments/SET_ENROLMENT_BY_PARENT",
  GetEnrolmentByClass: "enrolments/GET_ENROLMENT_BY_CLASS",
  SetEnrolmentByClass: "enrolments/SET_ENROLMENT_BY_CLASS",
  SetState: "enrolments/SET_STATE",
  ShowModal: "enrolments/SHOW_MODAL",
  HideModal: "enrolments/HIDE_MODAL",
  GetSelected: "enrolments/GET_SELECTED",
  UpdateEnrolment: "enrolments/UPDATE_ENROLMENT",
};

export const actions = {
  CreateEnrolment: (payload) => ({
    type: actionTypes.CreateEnrolment,
    payload,
  }),
  GetPrevEnrolmentByParent: (payload) => ({
    type: actionTypes.GetPrevEnrolmentByParent,
    payload,
  }),
  SetPrevEnrolmentByParent: (payload) => ({
    type: actionTypes.SetPrevEnrolmentByParent,
    payload,
  }),
  GetFutureEnrolmentByParent: (payload) => ({
    type: actionTypes.GetFutureEnrolmentByParent,
    payload,
  }),
  SetFutureEnrolmentByParent: (payload) => ({
    type: actionTypes.SetFutureEnrolmentByParent,
    payload,
  }),
  GetSelected: (payload) => ({
    type: actionTypes.GetSelected,
    payload,
  }),

  GetEnrolmentByParent: (payload) => ({
    type: actionTypes.GetEnrolmentByParent,
    payload,
  }),
  SetEnrolmentByParent: (payload) => ({
    type: actionTypes.SetEnrolmentByParent,
    payload,
  }),

  GetEnrolmentByClass: (payload) => ({
    type: actionTypes.GetEnrolmentByClass,
    payload,
  }),
  SetEnrolmentByClass: (payload) => ({
    type: actionTypes.SetEnrolmentByClass,
    payload,
  }),
  UpdateEnrolment: (payload) => ({
    type: actionTypes.UpdateEnrolment,
    payload,
  }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
};
