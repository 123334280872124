import {
  getAll,
  deleteVenue,
  create,
  getSelected,
  updateSelected,
  getAllVenuesByUuid,
} from "../../services/venues";
import { actionTypes, actions } from "../actions/venues";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    // yield put(
    //   actions.setState({
    //     meta: {
    //       list: {
    //         loading: true,
    //       },
    //     },
    //   })
    // );
    try {
      const result = yield call(getAll);
      yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      // return yield put(
      //   actions.setState({
      //     meta: {
      //       list: {
      //         loading: false,
      //       },
      //     },
      //   })
      // );
    }
  });

  yield takeLatest(actionTypes.GetListByUuid, function* getListByUuid(payload) {
    try {
      const result = yield call(getAllVenuesByUuid, payload);
      yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.UpdateVenue, function* updateSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedVenue, function* getSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedVenue({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateVenue, function* createVenue(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteVenue, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteVenue, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
