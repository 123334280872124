import { actionTypes } from "../actions/students";

const initialStudentState = {
  list: [],
  selected: {},
  studentsByParents: [],
  // meta: {},
};

const studentReducer = (state = initialStudentState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, studentsByParents } = action.payload;
      return {
        ...state,
        list,
        studentsByParents,
      };
    }

    case actionTypes.GetSelectedStudent: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default studentReducer;
