export const actionTypes = {
  GetList: "attendance/GET_LIST",
  SetState: "attendance/SET_STATE",
  CreateAttendance: "attendance/CREATE_ATTENDANCE",
  UpdateAttendance: "attendance/UPDATE_ATTENDANCE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateAttendance: (payload) => ({
    type: actionTypes.CreateAttendance,
    payload,
  }),
  UpdateAttendance: (payload) => ({
    type: actionTypes.UpdateAttendance,
    payload,
  }),
};
