import { actionTypes } from "../actions/products";

const initialProductState = {
  list: [],
  selected: {},
  productsByCategories: [],
  // meta: {},
};

const productReducer = (state = initialProductState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, productsByCategories, publicProducts } = action.payload;
      return {
        ...state,
        list,
        productsByCategories,
        publicProducts,
      };
    }
    case actionTypes.SetProductsByCategory: {
      const { productsByCategory } = action.payload;
      return {
        ...state,
        productsByCategory,
      };
    }
    case actionTypes.GetSelectedProduct: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default productReducer;
