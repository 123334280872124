import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import MuiAlert from "@material-ui/lab/Alert";

import { FormattedMessage, injectIntl } from "react-intl";
import { Input } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { resetPassword } from "../_redux/authCrud";
import { toast } from "../../../components/Toastr";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SigninSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .min(6, "Too Short!"),
  confirmPassword: Yup.string()
    .required("Required")
    .test(
      "match",
      "Password does not match", // your error message
      function() {
        return this.parent.password === this.parent.confirmPassword;
      }
    ),
});

const ResetPassword = (props) => {
  const {
    auth: { meta },
    match,
    history,
  } = props;

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };

  return (
    <>
      <div className="d-flex flex-center mb-15">
        <Link to="/" className="flex-column-auto mt-5">
          <img
            alt="Logo"
            className="max-h-70px"
            src={toAbsoluteUrl("/media/Logo/logo-quest.png")}
          />
        </Link>
      </div>
      <div className="kt-login__signin ">
        <div className="mb-20" style={{ textAlign: "center" }}>
          <h3>
            <FormattedMessage id="Reset Password" />
          </h3>
        </div>

        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={SigninSchema}
          onSubmit={(values) => {
            resetPassword({
              ...values,
              type: match.params.type,
              uuid: match.params.uuid,
            })
              .then(({ data }) => {
                toast.success("Password updated successfully");
                if (match.params.type === "admin") {
                  history.push("/auth/admin/login");
                } else {
                  history.push("/auth/login");
                }
              })
              .catch((error) => {
                console.log(error);
                if (error?.response?.data?.message) {
                  toast.error(error?.response?.data?.message);
                } else {
                  toast.error(
                    "Something went wrong. Please try after some time."
                  );
                }
              });
          }}
        >
          {({ status, handleSubmit, setFieldValue }) => (
            <form
              className="form"
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <Field
                  name="password"
                  component={Input}
                  placeholder="New Password"
                >
                  {({ field, form, meta }) => (
                    <div>
                      <label>New Password</label>
                      <input
                        type="password"
                        {...field}
                        className={`${getInputClasses(meta)}`}
                        placeholder="Enter Password"
                      />
                      {meta.touched && meta.error && (
                        <div
                          style={{ textAlign: "center" }}
                          className="error invalid-feedback"
                        >
                          {meta.error}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field
                  name="confirmPassword"
                  component={Input}
                  placeholder="Confirm Password"
                  label="Confirm New Password"
                >
                  {({ field, form, meta }) => (
                    <div>
                      <label>Confirm New Password</label>
                      <input
                        type="password"
                        {...field}
                        className={`${getInputClasses(meta)}`}
                        placeholder="Confirm Password"
                      />
                      {meta.touched && meta.error && (
                        <div
                          style={{ textAlign: "center" }}
                          className="error invalid-feedback"
                        >
                          {meta.error}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div class="kt-login__actions" style={{ textAlign: "center" }}>
                <button
                  id="kt_login_signin_submit"
                  className="font-weight-bold px-9 py-4 my-3 mx-4 login_button"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  <span>Reset</span>
                  {meta && meta.login && meta.login.loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
              {/* end::Actions */}
            </form>
          )}
        </Formik>
        {/*end::Form*/}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default injectIntl(connect(mapStateToProps, null)(ResetPassword));
