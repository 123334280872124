import { actionTypes } from "../actions/classes";

const initialLessonState = {
  list: [],
  classesByLocation: [],
  groups: [],
  meta: {},
  lessonsByClass: [],
  selected: {},
};

const classesReducer = (state = initialLessonState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, classesByLocation, meta, selected } = action.payload;
      return {
        ...state,
        list,
        selected,
        classesByLocation,
        meta: { ...meta },
      };
    }

    case actionTypes.SetLessonsByClass: {
      const { lessonsByClass } = action.payload;
      return {
        ...state,
        lessonsByClass,
      };
    }

    default:
      return state;
  }
};

export default classesReducer;
