export const actionTypes = {
  GetList: "lessons/GET_LIST",
  GetGroupList: "lessons/GET_GROUP_LIST",
  SetState: "lessons/SET_STATE",
  CreateLessons: "lessons/CREATE_LESSONS",
  UpdateLessons: "lessons/UPDATE_LESSONS",
  GetLessonsByLocation: "lessons/GET_LESSONS_BY_LOCATION",
  GetTutorLessons: "lessons/GET_TUTOR_LESSONS",
  GetLessonsByClass: "lessons/GET_LESSONS_BY_CLASS",
  SetLessonsByClass: "lessons/SET_LESSONS_BY_CLASS",
  GetSelectedLesson: "lessons/GET_SELECTED_LESSON",
  SetSelectedLesson: "lessons/SET_SELECTED_LESSON",
  DeleteLesson: "lessons/DELETE_LESSON",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateLessons: (payload) => ({ type: actionTypes.CreateLessons, payload }),
  UpdateLessons: (payload) => ({ type: actionTypes.UpdateLessons, payload }),
  GetLessonsByLocation: (payload) => ({
    type: actionTypes.GetLessonsByLocation,
    payload,
  }),
  GetLessonsByClass: (payload) => ({
    type: actionTypes.GetLessonsByClass,
    payload,
  }),
  SetLessonsByClass: (payload) => ({
    type: actionTypes.SetLessonsByClass,
    payload,
  }),

  GetSelectedLesson: (payload) => ({
    type: actionTypes.GetSelectedLesson,
    payload,
  }),
  SetSelectedLesson: (payload) => ({
    type: actionTypes.SetSelectedLesson,
    payload,
  }),
  DeleteLesson: (payload) => ({
    type: actionTypes.DeleteLesson,
    payload,
  }),
};
