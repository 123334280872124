import { actionTypes } from "../actions/enrolment";

const initialEnrolmentState = {
  list: [],
  count: "",
  show: false,
  prevEnrolmentsByParent: [],
  futureEnrolmentsByParent: [],
  enrolmentsByParent: [],
  enrolmentsByClass: [],
};

const enrolmentTypesReducer = (state = initialEnrolmentState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, count, meta, show, selected } = action.payload;
      return {
        ...state,
        list,
        count,
        show,
        selected,
        meta: { ...meta },
      };
    }

    case actionTypes.SetPrevEnrolmentByParent: {
      const { prevEnrolmentsByParent } = action.payload;
      return {
        ...state,
        prevEnrolmentsByParent,
      };
    }

    case actionTypes.SetFutureEnrolmentByParent: {
      const { futureEnrolmentsByParent } = action.payload;
      return {
        ...state,
        futureEnrolmentsByParent,
      };
    }

    case actionTypes.SetEnrolmentByParent: {
      const { enrolmentsByParent } = action.payload;
      return {
        ...state,
        enrolmentsByParent,
      };
    }

    case actionTypes.SetEnrolmentByClass: {
      const { enrolmentsByClass } = action.payload;
      return {
        ...state,
        enrolmentsByClass,
      };
    }

    default:
      return state;
  }
};

export default enrolmentTypesReducer;
