import { actionTypes } from "../actions/notifications";

const initialNotificationState = {
  list: [],
  meta: {},
  groupList: [],
  drawer: false,
};

const notificationReducer = (state = initialNotificationState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }

    case actionTypes.SetDrawer: {
      const { drawer } = action.payload;
      return {
        ...state,
        drawer,
      };
    }

    case actionTypes.SetGroupList: {
      const { groupList, meta } = action.payload;
      return {
        ...state,
        groupList,
        meta: { ...meta },
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
