import {
  getAll,
  deleteLocations,
  create,
  getSelected,
  updateSelected,
  getAllLocationsByUuid,
  searchNearLocation,
  getAllLocationsAndVenue,
} from "../../services/locations";
import { actionTypes, actions } from "../actions/locations";
import * as classActions from "./classes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.SetMapSelectedLocation, function* setLocation({
    payload,
  }) {
    yield put(
      actions.SetMapZoom({
        zoom: 14,
      })
    );
    yield put(
      actions.SetSelectedLocation({
        selectedInMap: payload,
      })
    );
  });

  yield takeLatest(
    actionTypes.SearchNearLocation,
    function* getSearchNearLocation(payload) {
      try {
        const result = yield call(searchNearLocation, payload);
        yield put(
          actions.SetMapZoom({
            zoom: 14,
          })
        );
        yield put(
          actions.setSearchedResults({
            searched: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedLocations,
    function* getSelectedLocationById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.setSelectedLocation({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateLocations,
    function* updateSelectedLocations(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.GetListByUuid, function* getListByUuid(payload) {
    const { tag } = payload.payload;
    try {
      const result = yield call(getAllLocationsByUuid, payload);
      yield put(
        actions.SetMapZoom({
          zoom: 14,
        })
      );
      yield put(
        actions.setSearchedResults({
          searched: result.data.results,
        })
      );

      yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.CreateLocations, function* createLocations(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.DeleteLocations,
    function* deleteSelectedLocations(payload) {
      try {
        const result = yield call(deleteLocations, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetAllLocationsAndVenue,
    function* getAllVenuesAndLocations() {
      try {
        const result = yield call(getAllLocationsAndVenue);
        yield put(
          actions.setState({
            locationsAndVenues: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.setState({
            locationsAndVenues: [],
          })
        );
        toast.error(error.response.data.error);
      }
    }
  );
}
