import {
  getAll,
  create,
  getPast,
  getUpcoming,
  deleteBirthdayParty,
  updateSelected,
  getSelected,
  getLocationSurcharge,
  getCancelledList,
} from "../../services/birthdayParty";
import { actionTypes, actions } from "../actions/birthdayParty";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetSelectedBirthdayParty,
    function* getSelectedBirthdayPartyById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.SetSelectedBirthdayParty({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetLocationSurcharge,
    function* getLocationSurchargeList() {
      try {
        const result = yield call(getLocationSurcharge);

        return yield put(
          actions.SetLocationSurcharge({
            locationSurcharge: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getCancelledBirthdayList() {
      try {
        const result = yield call(getCancelledList);

        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetPastBirthdayParty, function* getPastList() {
    try {
      const result = yield call(getPast);

      return yield put(
        actions.SetPastBirthdayParty({
          pastList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetFutureBirthdayParty,
    function* getFutureList() {
      try {
        const result = yield call(getUpcoming);
        return yield put(
          actions.SetFutureBirthdayParty({
            upcomingList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.CreateBirthdayParty,
    function* createBirthdayParty(payload) {
      try {
        const result = yield call(create, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.DeleteBirthdayParty,
    function* deleteSelectedBirthdayParty(payload) {
      try {
        const result = yield call(deleteBirthdayParty, payload.payload);
        yield put({
          type: actionTypes.GetFutureBirthdayParty,
        });
        yield put({
          type: actionTypes.GetPastBirthdayParty,
        });
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateBirthdayParty,
    function* updateSelectedBirthdayParty(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);

        // yield put({
        //   type: actionTypes.GetFutureBirthdayParty,
        // });
        // yield put({
        //   type: actionTypes.GetPastBirthdayParty,
        // });
        // yield put({
        //   type: actionTypes.GetList,
        // });

        return toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
