export const actionTypes = {
  GetList: "students/GET_LIST",
  GetGroupList: "students/GET_GROUP_LIST",
  GetStudentsByParent: "students/GET_STUDENTS_BY_PARENT",
  SetState: "students/SET_STATE",
  CreateStudent: "students/CREATE_STUDENT",
  EditStudent: "students/EDIT_STUDENT",
  DeleteStudent: "students/DELETE_STUDENT",
  GetSelectedStudent: "students/GET_SELECTED_STUDENT",
  UpdateStudent: "students/UPDATE_STUDENT",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createStudent: (payload) => ({ type: actionTypes.CreateStudent, payload }),
  editStudent: (payload) => ({ type: actionTypes.EditStudent, payload }),
  getSelectedStudent: (payload) => ({
    type: actionTypes.GetSelectedStudent,
    payload,
  }),
  GetStudentsByParent: (payload) => ({
    type: actionTypes.GetStudentsByParent,
    payload,
  }),
};
