import moment from "moment";
import React from "react";

export const DATE_FORMAT = "DD/MM/YYYY";

export const PAGINATION_PAGE_LIMIT = 15;

export const businessOptions = [
  {
    text: "Ginger Sports",
    value: "ginger",
  },
  {
    text: "QuestFA",
    value: "quest",
  },
];

export const bookOptions = [
  {
    name: "Birthday Party",
    link: "https://questfa.com.au/birthday-parties/",
  },
  {
    name: "1-2-1 Fun Soccer",
    link: "https://questfa.com.au/1-2-1-coaching/",
  },
  {
    name: "Book A Coach",
    link: "https://questfa.com.au/book-a-coach/",
  },
  {
    name: "Play Teqball",
    link: "https://questfa.com.au/teqball/",
  },
  {
    name: "Play Hungerball",
    link: "https://questfa.com.au/hungerball/",
  },
];

export const payInFullText =
  "When you choose the PAY IN FULL option, you are choosing to pay for the rest of the calendar year. If you PAY IN FULL before 31 March, you'll get a FREE KIT, 10% off voucher for a Birthday Party, & 25% off voucher for 1-2-1 Soccer.";

export const payMonthlyText =
  "When you choose the PAY MONTHLY option, you are choosing to pay every month. When you register for the first time, we bill you for the remaining sessions in the month. After that, we bill you on the first Sunday of every month. Just like Netflix, you can cancel at any time.";

export const enrolmentBeforeRegisterText =
  "If you are registering part way through the month, you will only be charged for the number of sessions left in the month. After that, you'll be charged monthly on the first Sunday of every month for the number of sessions in that month.  Just like Netflix, you can cancel at any time.";

export const ENROLMENT_DISCOUNT = process.env.REACT_APP_ENROLMENT_DISCOUNT;

export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const paymentMethods = [
  { text: "Credit Card", value: "credit" },
  { text: "Direct Debit", value: "direct" },
  // { text: "Bank Transfer", value: "transfer" },
];

export const paymentOptions = [
  { text: "Pay in Full", value: "full" },
  // { text: "Pay per term", value: "term" },
  { text: "Pay monthly", value: "monthly" },
];

export const paymentType = [
  { text: "Price per lesson", value: "lesson" },
  { text: "Price per class ", value: "class" },
];

export const merchandiseOptions = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

export const photoVideoPermissionOptions = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

export const sizeOptions = [
  { text: "2", value: "2" },
  { text: "4", value: "4" },
  { text: "6", value: "6" },
  { text: "8", value: "8" },
];

export const genderOptions = [
  { text: "Male", value: "Male" },
  { text: "Female", value: "Female" },
  { text: "Other", value: "Other" },
];

export const parseClassNameByDays = (cl) => {
  let name = "";
  if ((cl && cl.repeat === "no") || !cl.repeat) {
    name =
      cl.lessons &&
      cl.lessons.reduce((obj, item) => Object.assign(obj, { ...item }), {}).day;
  } else {
    for (let day of cl.days) {
      if (name.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  return `${name}`;
};

export const showOptionsForRecurring = (classes, selectedClasses) => {
  let show = false;
  if (classes && selectedClasses) {
    for (let cl of classes) {
      for (let selected of selectedClasses) {
        if (cl._id === selected.value) {
          if (cl.payment === "fixed") {
            show = true;
          }
        }
      }
    }
  }

  return show;
};

export const parseDiscount = (selectedClasses, classes) => {
  let show = false;
  let discount = 0;
  if (classes && selectedClasses) {
    for (let cl of classes) {
      for (let selected of selectedClasses) {
        if (cl._id === selected.value) {
          if (parseFloat(cl.additional_child_discount) > 0) {
            show = true;
            discount = cl.additional_child_discount;
          }
        }
      }
    }
  }

  return { show, discount };
};

export const showPaymentMethod = (classes, selectedClasses) => {
  let show = false;
  for (let cl of classes) {
    for (let selected of selectedClasses) {
      if (cl._id === selected.value) {
        if (cl.payment === "fixed") {
          show = true;
        }
      }
    }
  }

  return show;
};

export const getTermsOfClasses = (classes, selectedClasses) => {
  let terms = "";
  for (let cl of classes) {
    for (let selected of selectedClasses) {
      if (selected.value === cl._id) {
        if (cl.terms) {
          if (terms.length > 0) {
            terms += " " + cl.terms;
          } else {
            terms += cl.terms;
          }
        }
      }
    }
  }

  return terms;
};

export const parseEnrolmentFormClassName = (cl) => {
  let name = "";
  if (cl.repeat === "no" || !cl.repeat) {
    name = cl.lessons.reduce((obj, item) => Object.assign(obj, { ...item }), {})
      .day;
  } else {
    for (let day of cl.days) {
      if (name.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  // return `${name} - ${cl.startTime} - ${cl.endTime}`;
  return cl.name;
};

export const parseClassDays = (cl) => {
  let name = "";
  if ((cl && cl.repeat === "no") || !cl.repeat) {
    name = cl.lessons.reduce((obj, item) => Object.assign(obj, { ...item }), {})
      .day;
  } else {
    for (let day of cl.days) {
      if (name.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  return `${cl.name} - ${name}`;
};

export const renderAmount = (classes, values, coupon) => {
  let amount = 0;
  let paid = false;
  let discount = 0;
  let fullCapactityClasses = [];
  let showMerchandise = false;

  if (values.classId) {
    for (let cl of values.classId) {
      for (let selected of classes) {
        if (cl.value === selected._id) {
          if (selected.include_kit) {
            showMerchandise = true;
          }
          if (
            selected.paymentType === "paid" &&
            selected &&
            selected.students &&
            parseInt(selected.capacity) > selected.students.length
          ) {
            paid = true;
            amount += parseFloat(selected.unit_price);
            discount = selected.additional_child_discount;
          }
          if (
            selected &&
            selected.students &&
            parseInt(selected.capacity) <= selected.students.length
          ) {
            fullCapactityClasses.push(selected);
          }
        }
      }
    }
  }

  const totalStudents = values.childs ? values.childs.length : 1;

  let calculatedAmount =
    totalStudents > 1
      ? amount + (totalStudents - 1) * amount * (1 - discount / 100)
      : amount;

  if (coupon) {
    if (coupon.type === "flat") {
      calculatedAmount = calculatedAmount - parseFloat(coupon.amount);
    } else if (coupon.type === "percentage") {
      calculatedAmount =
        calculatedAmount * (1 - parseFloat(coupon.amount) / 100);
    }
  }

  return {
    paid,
    amount: parseFloat(calculatedAmount).toFixed(2),
    fullCapactityClasses,
    showMerchandise,
  };
};

export const timeFormatToSort = (date) => {
  if (!date) {
    return;
  }
  const time = date.split("/");

  return moment(`${time[1] + "/" + time[0] + "/" + time[2]}`).unix();
};

const renderPrimaryName = (primaryInstructor, instructors) => {
  if (primaryInstructor) {
    let name = `${primaryInstructor.firstName +
      " " +
      primaryInstructor.lastName}*`;

    return name;
  } else {
    return "";
  }
};

export const renderInstructorsName = (additionalInstructors) => {
  let name = "";
  for (let key in additionalInstructors) {
    if (name.length > 0) {
      name +=
        ", " +
        additionalInstructors[key].firstName +
        " " +
        additionalInstructors[key].lastName;
    } else {
      name +=
        ", " +
        additionalInstructors[key].firstName +
        " " +
        additionalInstructors[key].lastName;
    }
  }

  return name;
};

export const sortLessonsAccToDate = (classes, instructors) => {
  return (
    classes &&
    classes
      .sort((b, a) => {
        let firstTime = timeFormatToSort(a.to);
        let secondTime = timeFormatToSort(b.to);
        firstTime = !isNaN(firstTime) ? firstTime : "";
        secondTime = !isNaN(secondTime) ? secondTime : "";

        return firstTime - secondTime;
      })
      .map((cl) => {
        return {
          includeKit: cl.include_kit,
          additionalChildDiscount: cl.additional_child_discount,
          commonId: cl._id,
          amountBasedOn: cl.amountBasedOn,
          business: cl.business,
          capacity: cl.capacity,
          venueName: cl.venue && cl.venue.name,
          locationName: cl.location && cl.location.name,
          venue: cl.venue && cl.venue._id,
          _id: cl._id,
          name: cl.name,
          startTime: cl.startTime,
          endTime: cl.endTime,
          location: cl.location && cl.location._id,
          program: cl.program,
          typeClass: true,
          repeat: cl.repeat,
          payment: cl.payment,
          level1: cl.level,
          type: cl.type,
          primaryInstructor: cl.primaryInstructor,
          additionalInstructors: cl.additionalInstructors,
          days: cl.days,
          paymentType: cl.paymentType,
          terms: cl.terms,
          tag: cl.tag,
          instructors:
            renderPrimaryName(cl.primaryInstructor, instructors) +
            renderInstructorsName(cl.additionalInstructors),
          frequency: cl.frequency,
          paymentDuration: cl.paymentDuration,
          unit_price: cl.unit_price,
          from: cl.from,
          to: cl.to,
          lessons: cl.lessons
            .sort((b, a) => {
              let firstTime = timeFormatToSort(a.date);
              let secondTime = timeFormatToSort(b.date);
              firstTime = !isNaN(firstTime) ? firstTime : "";
              secondTime = !isNaN(secondTime) ? secondTime : "";

              return firstTime - secondTime;
            })
            .map((lesson) => {
              return {
                ...lesson,
                typeLesson: true,
                primaryInstructor:
                  lesson.primaryInstructor &&
                  lesson.primaryInstructor === cl.primaryInstructor._id
                    ? cl.primaryInstructor
                    : instructors
                        .filter(
                          (instr) => lesson.primaryInstructor === instr._id
                        )
                        .reduce(
                          (obj, item) => Object.assign(obj, { ...item }),
                          {}
                        ),
              };
            }),
        };
      })
  );
};

export const parseLessons = (classes) => {
  return (
    classes &&
    classes
      .map((cls) =>
        cls.lessons.map((lesson) => {
          return {
            ...lesson,
            name: cls.name,
            classId: cls._id,
            locationName: cls.location && cls.location.name,
            start: cls.startTime,
            end: cls.endTime,
            startTime: moment(
              lesson.date + " " + lesson.startTime,
              "DD-MM-YYYY HH:mm"
            ).format("LLLL"),
            endTime: moment(
              lesson.date + " " + lesson.endTime,
              "DD-MM-YYYY HH:mm"
            ).format("LLLL"),
          };
        })
      )
      .flat()
  );
};

export const parseClassName = (cl, margin) => {
  let name = "";
  if (cl.repeat === "no" || !cl.repeat) {
    name = cl.lessons.reduce((obj, item) => Object.assign(obj, { ...item }), {})
      .day;
  } else {
    for (let day of cl.days) {
      if (name.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  return (
    <React.Fragment>
      <p>{name}</p>
      {margin && <br />}
      <p className={margin && "mt-4"}>
        {cl.startTime} - {cl.endTime}
      </p>
    </React.Fragment>
  );
};

export const checkCookie = () => {
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
