export const actionTypes = {
  GetList: "parents/GET_LIST",
  GetGroupList: "parents/GET_GROUP_LIST",
  SetState: "parents/SET_STATE",
  CreateParent: "parents/CREATE_PARENT",
  EditParent: "parents/EDIT_PARENT",
  DeleteParent: "parents/DELETE_PARENT",
  GetSelectedParent: "parents/GET_SELECTED_PARENT",
  UpdateParent: "parents/UPDATE_PARENT",
  GetPaymentMethods: "parents/GET_PAYMENT_METHODS",
  SetPaymentMethods: "parents/SET_PAYMENT_METHODS",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createParent: (payload) => ({ type: actionTypes.CreateParent, payload }),
  editParent: (payload) => ({ type: actionTypes.EditParent, payload }),
  getSelectedParent: (payload) => ({
    type: actionTypes.GetSelectedParent,
    payload,
  }),
  setPaymentsMethods: (payload) => ({
    type: actionTypes.SetPaymentMethods,
    payload,
  }),
};
