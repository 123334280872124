import { actionTypes } from "../actions/tutors";

const initialTutorsState = {
  list: [],
  selected: {},
  scheduleList: [],
  // meta: {},
};

const tutorReducer = (state = initialTutorsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.GetSelectedTutor: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }
    case actionTypes.SetSchedule: {
      const { scheduleList } = action.payload;
      return {
        ...state,
        scheduleList,
      };
    }

    default:
      return state;
  }
};

export default tutorReducer;
