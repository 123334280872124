import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/locations/all");
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/locations/get/${payload.id}`);
  }
}

export function getAllLocationsByUuid({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.tag || payload.business) {
    return baseApi.get(`/locations/location/${payload.uuid}`, {
      params: { tag: payload.tag, business: payload.business },
    });
  } else {
    return baseApi.get(`/locations/location/${payload.uuid}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/locations/create`, payload);
}

export function deleteLocations(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/locations/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/locations/update/${payload.id}`, payload);
}

export function searchNearLocation({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  const { latitude, longitude } = payload;

  return baseApi.post(`/locations/find/radius/${latitude}/${longitude}`);
}

export function getAllLocationsAndVenue() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/locations/getAllLocationsAndVenue`);
}
