import {
  createEnrolment,
  getAll,
  getOne,
  getPrevEnrolmentByParent,
  getFutureEnrolmentByParent,
  getEnrolmentByParent,
  getEnrolmentByClass,
  updateEnrolment,
} from "../../services/enrolments";
import { actionTypes, actions } from "../actions/enrolment";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        list: [],
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          list: [],
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.UpdateEnrolment,
    function* UpdateSingleEnrolment({ payload }) {
      try {
        const result = yield call(updateEnrolment, payload);

        if (payload.changeEnrolmentStatus) {
          toast.success(
            `Status updated successfully to ${payload.enrolmentStatus}`
          );

          yield put({
            type: actionTypes.GetEnrolmentByParent,
            parentId: payload.parent._id,
          });

          yield put({
            type: actionTypes.GetList,
          });
        } else {
          toast.success(result.data.message);
        }

        yield put({
          type: actionTypes.GetEnrolmentByParent,
          parentId: payload.parent._id,
        });

        return;
      } catch (error) {
        if (
          error.response.data.code === 11000 &&
          error.response.data.name === "MongoError"
        ) {
          return toast.error("Email already exist.");
        }
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        return yield put(
          actions.setState({
            meta: {
              list: {
                loading: false,
              },
            },
          })
        );
      }
    }
  );

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      const result = yield call(getOne, payload);

      return yield put(
        actions.setState({
          selected: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetEnrolmentByParent, function* getEnrolmentList(
    payload
  ) {
    try {
      const result = yield call(getEnrolmentByParent, payload);

      return yield put(
        actions.SetEnrolmentByParent({
          enrolmentsByParent: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetEnrolmentByClass, function* getEnrolmentList(
    payload
  ) {
    try {
      const result = yield call(getEnrolmentByClass, payload.payload);

      return yield put(
        actions.SetEnrolmentByClass({
          enrolmentsByClass: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetPrevEnrolmentByParent,
    function* getEnrolmentList(payload) {
      try {
        const result = yield call(getPrevEnrolmentByParent, payload);

        return yield put(
          actions.SetPrevEnrolmentByParent({
            prevEnrolmentsByParent: result.data.results,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetFutureEnrolmentByParent,
    function* getEnrolmentList(payload) {
      try {
        const result = yield call(getFutureEnrolmentByParent, payload);

        return yield put(
          actions.SetFutureEnrolmentByParent({
            futureEnrolmentsByParent: result.data.results,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.CreateEnrolment, function* CreateEnrolment({
    payload,
  }) {
    try {
      const result = yield call(createEnrolment, payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );
    } catch (error) {
      if (
        error.response.data.code === 11000 &&
        error.response.data.name === "MongoError"
      ) {
        return toast.error("Email already exist.");
      }
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.ShowModal, function* showModal() {
    yield put(
      actions.setState({
        show: true,
      })
    );
  });

  yield takeLatest(actionTypes.HideModal, function* hideModal() {
    yield put(
      actions.setState({
        show: false,
      })
    );
  });
}
