import axios from "axios";
import baseApi from "../../../../api/baseApi";

export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";
export const PROFILE_UPDATE_URL = "/auth/update-profile";
export const PARENT_PROFILE_UPDATE_URL = "/auth/update-parent";
export const VALIDATE_USER_URL = "/auth/validateUser";
export const RESET_PASSWORD_URL = "/auth/resetPassword";

export const ME_URL = "api/me";

export function login(values) {
  return baseApi.post(LOGIN_URL, { ...values, business: "quest" });
}

export function checkEmailAddress(payload) {
  return baseApi.post(`/auth/validate`, payload);
}

export function updateProfile(values) {
  return baseApi.post(PROFILE_UPDATE_URL, values);
}

export function updateParentProfile(values) {
  return baseApi.post(PARENT_PROFILE_UPDATE_URL + `/${values.id}`, values);
}

export function register(values) {
  return baseApi.post(REGISTER_URL, values);
}

export function requestPassword(email) {
  return baseApi.post(REQUEST_PASSWORD_URL, { email, business: "quest" });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function validateAUser(payload) {
  const { token } = payload.token;

  if (token && token.length > 0) {
    baseApi.defaults.headers.common["Authorization"] = `${token}`;

    return baseApi.post(VALIDATE_USER_URL);
  }
}

export function resetPassword(payload) {
  return baseApi.post(RESET_PASSWORD_URL, payload);
}
