import {
  getAll,
  create,
  getPast,
  getUpcoming,
  deletePlay,
  updateSelected,
  getSelected,
  getCancelledList,
  getLocationSurcharge,
} from "../../services/play";
import { actionTypes, actions } from "../actions/play";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetLocationSurcharge,
    function* getLocationSurchargeList() {
      try {
        const result = yield call(getLocationSurcharge);

        return yield put(
          actions.SetLocationSurcharge({
            locationSurcharge: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetSelectedPlay, function* getSelectedPlayById(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.SetSelectedPlay({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getCancelledItemsList() {
      try {
        const result = yield call(getCancelledList);

        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetPastPlay, function* getPastList() {
    try {
      const result = yield call(getPast);
      return yield put(
        actions.SetPastPlay({
          pastList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetFuturePlay, function* getFutureList() {
    try {
      const result = yield call(getUpcoming);
      return yield put(
        actions.SetFuturePlay({
          upcomingList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreatePlay, function* createPlay(payload) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeletePlay, function* deleteSelectedPlay(
    payload
  ) {
    try {
      const result = yield call(deletePlay, payload.payload);
      yield put({
        type: actionTypes.GetFuturePlay,
      });
      yield put({
        type: actionTypes.GetPastPlay,
      });
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdatePlay, function* updateSelectedPlay(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      return toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
