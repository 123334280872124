/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect, withRouter } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
// import Login from "./Login";
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import ResetPassword from "./ResetPassword";

const Login = React.lazy(() => import("./Login"));

const Registration = React.lazy(() => import("./Registration"));

const ForgotPassword = React.lazy(() => import("./ForgotPassword"));

function AuthPage({ location }) {
  return (
    <>
      <div
        style={{ height: "100%" }}
        className="kt-page--loading-enabled kt-page--loading kt-header--fixed kt-header--minimize-topbar kt-header-mobile--fixed kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-subheader--enabled kt-subheader--transparent kt-page--loading"
      >
        {/*begin::Login*/}
        <div
          style={{ height: "100%" }}
          className="kt-grid kt-grid--ver kt-grid--root kt-page"
        >
          <div
            className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v3 kt-login--signin"
            id="kt_login"
            style={{ height: "100%" }}
          >
            <div
              className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
                height: "100%",
              }}
            >
              {/*  <div className="d-flex flex-row-fluid flex-column justify-content-between ">
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>

              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to Metronic!
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  The ultimate Bootstrap & React 16 admin theme framework for
                  next generation web apps.
                </p>
              </div>

              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2020 Metronic
                </div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
              {/*begin::Aside*/}

              {/*begin::Content*/}
              <div
                class="kt-grid__item kt-grid__item--fluid kt-login__wrapper"
                style={{ margin: "auto auto" }}
              >
                {/*begin::Content header*/}
                {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                  {location.pathname === "/auth/login" && (
                    <>
                      <span className="font-weight-bold text-dark-50">
                        Don't have an account yet?
                      </span>
                      <Link
                        to="/auth/registration"
                        className="font-weight-bold ml-2"
                        id="kt_login_signup"
                      >
                        Sign Up!
                      </Link>
                    </>
                  )}
                  {location.pathname === "/auth/registration" && (
                    <>
                      <span className="font-weight-bold text-dark-50">
                        Already have a account?
                      </span>
                      <Link
                        to="/auth/login"
                        className="font-weight-bold ml-2"
                        id="kt_login_signup"
                      >
                        Sign in!
                      </Link>
                    </>
                  )}
                </div> */}
                {/*end::Content header*/}

                {/* begin::Content body */}
                <div class="kt-login__container">
                  <Switch>
                    <ContentRoute path="/auth/login" component={Login} />
                    <ContentRoute
                      path="/auth/registration"
                      component={Registration}
                    />

                    <ContentRoute
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                    />
                    <ContentRoute
                      path="/auth/:type/reset/:uuid"
                      component={ResetPassword}
                    />
                    <Redirect from="/auth" exact={true} to="/auth/login" />
                    <Redirect to="/auth/login" />
                  </Switch>
                </div>
                {/*end::Content body*/}

                {/* begin::Mobile footer */}
                {/* <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                  <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                    &copy; 2020 Metronic
                  </div>
                  <div className="d-flex order-1 order-sm-2 my-2">
                    <Link
                      to="/terms"
                      className="text-dark-75 text-hover-primary"
                    >
                      Privacy
                    </Link>
                    <Link
                      to="/terms"
                      className="text-dark-75 text-hover-primary ml-4"
                    >
                      Legal
                    </Link>
                    <Link
                      to="/terms"
                      className="text-dark-75 text-hover-primary ml-4"
                    >
                      Contact
                    </Link>
                  </div>
                </div> */}
                {/* end::Mobile footer */}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Login*/}
          </div>
        </div>
      </div>
    </>
  );
}

AuthPage = withRouter(AuthPage);

export { AuthPage };
