export const actionTypes = {
  GetList: "venues/GET_LIST",
  GetListByUuid: "venues/GET_LIST_BY_UUID",
  GetGroupList: "venues/GET_GROUP_LIST",
  SetState: "venues/SET_STATE",
  CreateVenue: "venues/CREATE_VENUE",
  EditVenue: "venues/EDIT_VENUE",
  DeleteVenue: "venues/DELETE_VENUE",
  GetSelectedVenue: "venues/GET_SELECTED_VENUE",
  UpdateVenue: "venues/UPDATE_VENUE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  GetListByUuid: (payload) => ({ type: actionTypes.GetListByUuid, payload }),
  createVenue: (payload) => ({ type: actionTypes.CreateVenue, payload }),
  editVenue: (payload) => ({ type: actionTypes.EditVenue, payload }),
  getSelectedVenue: (payload) => ({
    type: actionTypes.GetSelectedVenue,
    payload,
  }),
};
