import {
  getAll,
  create,
  updateSelected,
  deleteOrder,
  getSelected,
  changeStatus,
} from "../../services/orders";
import { actionTypes, actions } from "../actions/orders";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedOrder, function* getSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      if (result)
        return yield put(
          actions.SetSelectedOrder({
            selected: result.data,
          })
        );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateOrders, function* createOrder(payload) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteOrders, function* deleteSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(deleteOrder, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ChangeStatus, function* chngeOrderStatus(
    payload
  ) {
    try {
      const result = yield call(changeStatus, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error("Something went wrong. Please try after some time.");
    }
  });

  yield takeLatest(actionTypes.UpdateOrder, function* updateSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
