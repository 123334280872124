export const actionTypes = {
  GetList: "birthdayparty/GET_LIST",
  GetGroupList: "birthdayparty/GET_GROUP_LIST",

  GetPastBirthdayParty: "birthdayparty/GET_PAST_LIST",
  SetPastBirthdayParty: "birthdayparty/SET_PAST_LIST",

  GetFutureBirthdayParty: "birthdayparty/GET_UPCOMING_LIST",
  SetFutureBirthdayParty: "birthdayparty/SET_UPCOMING_LIST",

  SetState: "birthdayparty/SET_STATE",
  CreateBirthdayParty: "birthdayparty/CREATE_BIRTHDAY_PARTY",
  DeleteBirthdayParty: "birthdayparty/DELETE_BIRTHDAY_PARTY",

  GetSelectedBirthdayParty: "birthdayparty/GET_SELECTED_BIRTHDAY_PARTY",
  SetSelectedBirthdayParty: "birthdayparty/SET_SELECTED_BIRTHDAY_PARTY",

  GetLocationSurcharge: "birthdayparty/GET_LOCATION_SURCHARGE",
  SetLocationSurcharge: "birthdayparty/SET_LOCATION_SURCHARGE",

  GetCancelledList: "birthdayparty/GET_CANCELLED_LIST",
  SetCancelledList: "birthdayparty/SET_CANCELLED_LIST",

  UpdateBirthdayParty: "birthdayparty/UPDATE_BIRTHDAY_PARTY",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateBirthdayParty: (payload) => ({
    type: actionTypes.CreateBirthdayParty,
    payload,
  }),
  UpdateBirthdayParty: (payload) => ({
    type: actionTypes.UpdateBirthdayParty,
    payload,
  }),
  GetSelectedBirthdayParty: (payload) => ({
    type: actionTypes.GetSelectedBirthdayParty,
    payload,
  }),
  SetSelectedBirthdayParty: (payload) => ({
    type: actionTypes.SetSelectedBirthdayParty,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  GetPastBirthdayParty: (payload) => ({
    type: actionTypes.GetPastBirthdayParty,
    payload,
  }),
  SetPastBirthdayParty: (payload) => ({
    type: actionTypes.SetPastBirthdayParty,
    payload,
  }),
  GetFutureBirthdayParty: (payload) => ({
    type: actionTypes.GetFutureBirthdayParty,
    payload,
  }),
  SetFutureBirthdayParty: (payload) => ({
    type: actionTypes.SetFutureBirthdayParty,
    payload,
  }),
  GetLocationSurcharge: (payload) => ({
    type: actionTypes.GetLocationSurcharge,
    payload,
  }),
  SetLocationSurcharge: (payload) => ({
    type: actionTypes.SetLocationSurcharge,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
};
