import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (payload.filter) {
    return baseApi.get(`/classes/all`, {
      params: { filter: payload.filter },
    });
  } else {
    return baseApi.get(`/classes/all`);
  }
}

export function getOne({ payload }) {
  return baseApi.get(`/classes/get/${payload.classId}`);
}

export function getAllClassesByLocation(payload) {
  if (payload.tag) {
    return baseApi.get(
      `/classes/location/${payload.locationId}/${payload.uuid}/${payload.type}`,
      {
        params: { tag: payload.tag },
      }
    );
  } else {
    return baseApi.get(
      `/classes/location/${payload.locationId}/${payload.uuid}/${payload.type}`
    );
  }
}

export function getAllLessonsByClass(payload) {
  const authToken = () =>
    localStorage && JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/getlessonsbyclass/${payload.classId}`);
}

// export function getById(payload) {
//   return baseApi.get(`/lessons/get/${payload.id}`);
// }

export function createClass({ payload }) {
  const authToken = () =>
    localStorage && JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/classes/create", payload);
}

// export function updateLesson(payload) {
//   return baseApi.put(`lessons/update/${payload.eventData.id}`, payload);
// }

// export function deleteLesson(payload) {
//   return baseApi.delete(`lessons/delete/${payload.id}`);
// }
