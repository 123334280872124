export const actionTypes = {
  GetList: "orders/GET_LIST",
  SetState: "orders/SET_STATE",
  CreateOrders: "orders/CREATE_ORDER",
  UpdateOrder: "orders/UPDATE_ORDER",
  DeleteOrders: "orders/DELETE_ORDER",
  GetSelectedOrder: "orders/GET_SELECTED_ORDER",
  SetSelectedOrder: "orders/SET_SELECTED_ORDER",
  ChangeStatus: "orders/CHANGE_STATUS",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateOrder: (payload) => ({
    type: actionTypes.CreateOrders,
    payload,
  }),
  ChangeStatus: (payload) => ({
    type: actionTypes.ChangeStatus,
    payload,
  }),
  UpdateOrder: (payload) => ({
    type: actionTypes.UpdateOrder,
    payload,
  }),
  GetSelectedOrder: (payload) => ({
    type: actionTypes.GetSelectedOrder,
    payload,
  }),
  SetSelectedOrder: (payload) => ({
    type: actionTypes.SetSelectedOrder,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
};
