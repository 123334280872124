import {
  getAll,
  createLesson,
  updateLesson,
  getAllLessonsByLocation,
  getLessonsByClass,
  getTutorLessons,
  getSelectedLesson,
  deleteLesson,
} from "../../services/lessons";
import { actionTypes, actions } from "../actions/lessons";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        list: [],
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          list: [],
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.GetLessonsByLocation,
    function* getLessonsByLocationId(payload) {
      try {
        const result = yield call(getAllLessonsByLocation, payload.payload);

        return yield put(
          actions.setState({
            lessonsByLocation: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.GetSelectedLesson, function* getSelectedId(
    payload
  ) {
    try {
      const result = yield call(getSelectedLesson, payload.payload);

      return yield put(
        actions.SetSelectedLesson({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.GetLessonsByClass, function* getLessonsByClassId(
    payload
  ) {
    try {
      const result = yield call(getLessonsByClass, payload.payload);
      return yield put(
        actions.SetLessonsByClass({
          lessonsByClass: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(
    actionTypes.GetTutorLessons,
    function* getLessonsOfCurrentTutor() {
      try {
        const result = yield call(getTutorLessons);

        return yield put(
          actions.setState({
            tutorLessons: result.data.results,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.UpdateLessons, function* updateLessons(payload) {
    try {
      const result = yield call(updateLesson, payload.payload);
      toast.success(result.data.message);
      return yield put(
        actions.SetLessonsByClass({
          lessonsByClass: result.data.results,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.CreateLessons, function* createLessons(payload) {
    try {
      const result = yield call(createLesson, payload.payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.DeleteLesson, function* deleteSelectedlesson(
    payload
  ) {
    try {
      const result = yield call(deleteLesson, payload.payload);
      toast.success(result.data.message);
      return yield put({
        type: actionTypes.GetLessonsByClass,
        payload: {
          classId: result.data.results.class,
        },
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
