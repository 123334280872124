import {
  getListByClass,
  getAll,
  deleteWaiting,
  update,
  getOne,
} from "../../services/waitingList";
import { actionTypes, actions } from "../actions/waitingList";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetAll, function* getAllWaiting(payload) {
    try {
      const result = yield call(getAll, payload);

      yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      const result = yield call(getOne, payload);

      yield put(
        actions.SetSelected({
          selected: result.data,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.GetListByClass, function* getListByClasses(
    payload
  ) {
    try {
      const result = yield call(getListByClass, payload);

      yield put(
        actions.setState({
          listBySelectedClass: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.DeleteWaiting, function* deleteWaitingEntry(
    payload
  ) {
    try {
      const result = yield call(deleteWaiting, payload);
      yield put({
        type: actionTypes.GetAll,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateWaiting, function* updateWaitingRecord(
    payload
  ) {
    try {
      const result = yield call(update, payload);
      toast.success(result.data.message);

      yield put({
        type: actionTypes.GetAll,
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
