export const actionTypes = {
  GetList: "bookacoach/GET_LIST",
  GetGroupList: "bookacoach/GET_GROUP_LIST",

  SetState: "bookacoach/SET_STATE",

  CreateBookaCoach: "bookacoach/CREATE_BOOK_A_COACH",
  DeleteBookaCoach: "bookacoach/DELETE_BOOK_A_COACH",

  GetSelectedBookaCoach: "bookacoach/GET_SELECTED_BOOK_A_COACH",
  SetSelectedBookaCoach: "bookacoach/SET_SELECTED_BOOK_A_COACH",

  GetCancelledList: "bookacoach/GET_CANCELLED_LIST",
  SetCancelledList: "bookacoach/SET_CANCELLED_LIST",

  GetPastBookaCoach: "bookacoach/GET_PAST_LIST",
  SetPastBookaCoach: "bookacoach/SET_PAST_LIST",

  GetScheduleBookaCoach: "bookacoach/GET_SCHEDULE_LIST",
  SetScheduleBookaCoach: "bookacoach/SET_SCHEDULE_LIST",

  GetFutureBookaCoach: "bookacoach/GET_UPCOMING_LIST",
  SetFutureBookaCoach: "bookacoach/SET_UPCOMING_LIST",

  UpdateBookaCoach: "bookacoach/UPDATE_BOOK_A_COACH",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateBookaCoach: (payload) => ({
    type: actionTypes.CreateBookaCoach,
    payload,
  }),
  UpdateBookaCoach: (payload) => ({
    type: actionTypes.UpdateBookaCoach,
    payload,
  }),
  GetSelectedBookaCoach: (payload) => ({
    type: actionTypes.GetSelectedBookaCoach,
    payload,
  }),
  SetSelectedBookaCoach: (payload) => ({
    type: actionTypes.SetSelectedBookaCoach,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
  GetPastBookaCoach: (payload) => ({
    type: actionTypes.GetPastBookaCoach,
    payload,
  }),
  SetPastBookaCoach: (payload) => ({
    type: actionTypes.SetPastBookaCoach,
    payload,
  }),
  GetFutureBookaCoach: (payload) => ({
    type: actionTypes.GetFutureBookaCoach,
    payload,
  }),
  SetFutureBookaCoach: (payload) => ({
    type: actionTypes.SetFutureBookaCoach,
    payload,
  }),

  GetScheduleBookaCoach: (payload) => ({
    type: actionTypes.GetScheduleBookaCoach,
    payload,
  }),
  SetScheduleBookaCoach: (payload) => ({
    type: actionTypes.SetScheduleBookaCoach,
    payload,
  }),
};
