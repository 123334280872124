export const actionTypes = {
  GetList: "tutors/GET_LIST",
  GetGroupList: "tutors/GET_GROUP_LIST",
  SetState: "tutors/SET_STATE",
  CreateTutor: "tutors/CREATE_TUTOR",
  EditTutor: "tutors/EDIT_TUTOR",
  DeleteTutor: "tutors/DELETE_TUTOR",
  GetSelectedTutor: "tutors/GET_SELECTED_TUTOR",
  UpdateTutor: "tutors/UPDATE_TUTOR",
  GetSchedule: "tutors/GET_SCHEDULE",
  SetSchedule: "tutors/SET_SCHEDULE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createTutor: (payload) => ({ type: actionTypes.CreateTutor, payload }),
  editTutor: (payload) => ({ type: actionTypes.EditTutor, payload }),
  getSelectedTutor: (payload) => ({
    type: actionTypes.GetSelectedTutor,
    payload,
  }),
  getSchedule: (payload) => ({
    type: actionTypes.GetSchedule,
    payload,
  }),
  setSchedule: (payload) => ({
    type: actionTypes.SetSchedule,
    payload,
  }),
};
