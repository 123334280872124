import { actionTypes } from "../actions/birthdayParty";

const initialBirthdayPartyReducer = {
  list: [],
  pastList: [],
  upcomingList: [],
  selected: {},
  locationSurcharge: [],
  cancelledList: [],
};

const birthdayPartyReducer = (state = initialBirthdayPartyReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetPastBirthdayParty: {
      const { pastList } = action.payload;
      return {
        ...state,
        pastList,
      };
    }
    case actionTypes.SetFutureBirthdayParty: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }

    case actionTypes.SetSelectedBirthdayParty: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetLocationSurcharge: {
      const { locationSurcharge } = action.payload;
      return {
        ...state,
        locationSurcharge,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    default:
      return state;
  }
};

export default birthdayPartyReducer;
