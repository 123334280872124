import { actionTypes } from "../actions/lessons";

const initialLessonState = {
  list: [],
  lessonsByLocation: [],
  lessonsByClass: [],
  selected: {},
  groups: [],
  meta: {},
};

const lessonReducer = (state = initialLessonState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, lessonsByLocation, tutorLessons, meta } = action.payload;
      return {
        ...state,
        list,
        lessonsByLocation,
        tutorLessons,
        meta: { ...meta },
      };
    }

    case actionTypes.SetLessonsByClass: {
      const { lessonsByClass } = action.payload;
      return {
        ...state,
        lessonsByClass,
      };
    }
    case actionTypes.SetSelectedLesson: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }
    default:
      return state;
  }
};

export default lessonReducer;
