import {
  getAll,
  create,
  getPast,
  getUpcoming,
  deleteInflatable,
  updateSelected,
  getSelected,
  getLocationSurcharge,
  getCancelledList,
} from "../../services/inflatable";
import { actionTypes, actions } from "../actions/inflatable";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetSelectedInflatable,
    function* getSelectedInflatableById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.SetSelectedInflatable({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetLocationSurcharge,
    function* getLocationSurchargeList() {
      try {
        const result = yield call(getLocationSurcharge);

        return yield put(
          actions.SetLocationSurcharge({
            locationSurcharge: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getCancelledItemsList() {
      try {
        const result = yield call(getCancelledList);

        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetPastInflatable, function* getPastList() {
    try {
      const result = yield call(getPast);
      return yield put(
        actions.SetPastInflatable({
          pastList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetFutureInflatable, function* getFutureList() {
    try {
      const result = yield call(getUpcoming);
      return yield put(
        actions.SetFutureInflatable({
          upcomingList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateInflatable, function* createInflatable(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.DeleteInflatable,
    function* deleteSelectedInflatable(payload) {
      try {
        const result = yield call(deleteInflatable, payload.payload);
        yield put({
          type: actionTypes.GetFutureInflatable,
        });
        yield put({
          type: actionTypes.GetPastInflatable,
        });
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateInflatable,
    function* updateSelectedInflatable(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);

        // yield put({
        //   type: actionTypes.GetFutureInflatable,
        // });
        // yield put({
        //   type: actionTypes.GetPastInflatable,
        // });
        // yield put({
        //   type: actionTypes.GetList,
        // });

        return toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
