/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch, connect } from "react-redux";

import { Logout, AuthPage } from "./modules/Auth";

const ParentBasePage = React.lazy(() =>
  import("./pages/ParentPortal/ParentPortalBasePage")
);
const BasePage = React.lazy(() => import("./BasePage"));

const TutorBasePage = React.lazy(() => import("./TutorBasePage"));

const ErrorsPage = React.lazy(() =>
  import("./modules/ErrorsExamples/ErrorsPage")
);

const Layout = React.lazy(() =>
  import("../_metronic/layout/components/Layout")
);

const EnrolmentPage = React.lazy(() =>
  import("./pages/Enrolments/EnrolmentPage")
);

const SecondEnrolmentPage = React.lazy(() =>
  import("./pages/Enrolments/VenueSelectedEnrolmentPage/index")
);

const BirthdayPartyPage = React.lazy(() =>
  import("./pages/BirthdayParty/BirthdayPartyCrud/CreateBirthdayParty")
);

const BookingPage = React.lazy(() =>
  import("./pages/Booking/BookingCrud/CreateBooking")
);

const BookaCoachPage = React.lazy(() =>
  import("./pages/BookaCoach/BookaCoachCrud/CreateBookaCoach")
);

const InflatablePage = React.lazy(() =>
  import("./pages/Inflatable/InflatableCrud/CreateInflatable")
);

const PlayPage = React.lazy(() => import("./pages/Play/PlayCrud/CreatePlay"));

const WeeklyEnrolmentPage = React.lazy(() =>
  import("./pages/Enrolments/WeeklyEnrolments.js")
);

const Routes = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.auth?.authToken?.token?.length > 0 && props?.auth?.user?._id) {
      dispatch({
        type: "[User] Validate User",
        payload: {
          token: props.auth.authToken,
        },
      });
    }
  }, [props?.auth?.authToken?.token]);

  const { isAuthorized, role } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      role: auth.user && auth.user.roleId ? auth.user.roleId.name : "",
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route
        exact
        key={Date.now()}
        path="/:uuid/weekly/:business"
        component={WeeklyEnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:uuid/weekly/:tag/:business"
        component={SecondEnrolmentPage}
      />

      <Route
        exact
        key={Date.now()}
        path="/:uuid/:location/weekly/:business"
        component={SecondEnrolmentPage}
      />

      <Route
        exact
        key={Date.now()}
        path="/:uuid/class/weekly/:classId/:business"
        component={SecondEnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:uuid/enrolment/:business"
        component={EnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:uuid/:venue/enrolment/:business"
        component={EnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:uuid/enrolment/:tag/:business"
        component={EnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:uuid/enrolment/:classId/:business"
        component={EnrolmentPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:organizationId/birthday/:business"
        component={BirthdayPartyPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:organizationId/booking/:business"
        component={BookingPage}
      />

      <Route
        exact
        key={Date.now()}
        path="/:organizationId/inflatable/:business"
        component={InflatablePage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:organizationId/bookacoach/:business"
        component={BookaCoachPage}
      />
      <Route
        exact
        key={Date.now()}
        path="/:organizationId/play/:product/:business"
        component={PlayPage}
      />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage props={props} />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          {role === "tutor" ? (
            <TutorBasePage />
          ) : role === "parent" ? (
            <ParentBasePage />
          ) : (
            <BasePage />
          )}
        </Layout>
      )}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const WrappedRoutes = connect(mapStateToProps)(Routes);

export { WrappedRoutes as Routes };
